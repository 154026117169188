<template>
  <div class="container" ref="container">
    <form @submit.prevent="submit()">
      <page-header
        :title="'직접 등록하지 않아도\n통화로 견적을 받을 수 있어요.'"
        titleSize="3.6rem"
        :subtitle="'연락처를 남겨주시면 최대한 빨리 연락드릴게요!'"
        />

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-3"></div>
        <h4>신청자 이름</h4>
        <!-- <p :style="{fontSize: '18px'}"
          >헤이비글 수수료 10%와 부가세를 포함하여 입력하세요.</p> -->
        <div class="ui-border-line ui-h-0 ui-mb-2"></div>

        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="formData.name"
          required
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-3"></div>
        <h4>상담 받을 연락처 (휴대폰 번호)</h4>
        <!-- <p :style="{fontSize: '18px'}"
          >의뢰 맞춤 내용으로 작성할수록 반응이 좋아요.</p> -->
        <div class="ui-border-line ui-h-0 ui-mb-2"></div>

        <div class="ui-border-line"></div>
        <input
          type="text"
          v-model="formData.email"
          v-mask="'####################'"
          required
          />
        <div class="ui-border-line" style="height: 10px"></div>
      </div>

      <div>
        <div class="ui-border-line ui-h-0 ui-mt-3"></div>
        <p>상담 신청 시 헤이비글 서비스의 <router-link to="/etc/terms">서비스 약관</router-link>,</p>
        <p><router-link to="/etc/privacy">개인정보 보호정책</router-link> 등에 동의하는 것으로 간주됩니다.</p>
      </div>

      <div class="ui-border-0 ui-mb-6"></div>

      <footer-box
        submitText="약관 동의 및 상담 신청하기"
        :submitCb="() => {}"
        :submitDisabled="!validate"
      >
      </footer-box>
    </form>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import device from 'current-device'

export default {
  name: 'Consulting',
  components: {
    PageHeader,
    FooterBox,
  },
  data() {
    const userData = this.$store.state.user.userData
    const platform = sessionStorage.getItem('platform')
    return {
      formData: {
        name: userData.userName, // 이름
        email: userData.phone, // 전화번호
        detail: '', // 내용
        device: platform, // 디바이스종류
        os: device.os, // OS정보
      },
    }
  },
  computed: {
    validate() {
      return this.formData.name && this.formData.email
    },
  },
  methods: {
    submit() {
      if (!this.validate) return

      const req = {
        method: 'post',
        url: `/user/contact`,
        data: this.formData,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.$toast('상담이 신청되었습니다.').back()
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.6rem !important;
  line-height: 6.6rem !important;
}

input, textarea {
  padding: 0;
  width: 100%;
  border: 0;
  font-size: 2rem;
  line-height: 3rem;
}

input {
  height: 10rem;
}

textarea {
  min-height: 14rem;
  padding: 3rem 0;
}

p {
  color: #979797;
  font-size: 2rem;
  a {
    color: #000;
    text-decoration: underline;
    text-underline-position: under;
  }
}
</style>
